import React from 'react'
import { graphql } from 'gatsby'
import { 
    Box,
    Container,
    Text,
} from '@chakra-ui/react'

import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import Seo from '../components/seo'
import PageTitle from '../components/PageTitle/PageTitle'

import Gallery from '@browniebroke/gatsby-image-gallery'

const Post = ({ location, data, pageContext }) => {
    if (!data) return null
    const document = data.prismicEvent.data;
    //console.log(document)
    
    const CustomWrapper = ({ children, onClick }) => (
        <div className="lightbox-wrapper" onClick={onClick} role="button" width="100%">
            { children }
        </div>
    )

    const lightboxOptions = {
        enableZoom: false,
    }

    let images = []
    console.log(document.event_photos)

    if (document.event_photos.length > 0 && document.event_photos[0].image.localFile !== null) {
      images = document.event_photos.map(({ image }) => ({
        ...image.localFile.childImageSharp
      }))
    }
    
    console.log(images)

    return (
        <>
        <Layout
            annoucementDisplay="none">
  <Seo title="Iris Biomedical | Technology" />
    <PageTitle 
      title={document.title.text}
      subtitle={`${document.event_date.text} - ${document.event_location.text}`}
    />
    <Container maxW="container.xl">
        <Text>
            <RichText render={document.content.raw} />
        </Text>
        {images && (
          <Box w="100%" className="gallery-container" mb="12">
          <Gallery images={images}
              customWrapper={CustomWrapper} 
              lightboxOptions={lightboxOptions}
              />
          {/* {photos.map((photo) => {
              return (
                  <GatsbyImage image={getImage(photo.image.localFile)} alt={photo.image.alt} />
              )
          })} */}
      </Box>
        )}
    </Container>

    </Layout>
        </>
    )
}

export const query = graphql`
query EventQuery($uid: String!) {
    prismicEvent(uid: {eq: $uid}) {
        uid
        data {
          title {
            text
          }
          event_photos {
            image {
                alt
              localFile {
                childImageSharp {
                    full: gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    thumb: gatsbyImageData(placeholder:BLURRED, width: 300, height: 300)
                }
              }
            }
          }
          event_location {
            text
          }
          event_date {
            text
          }
          content {
            raw
          }
        }
    }
  }
  `

export default Post